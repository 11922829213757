import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Link } from "gatsby";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data; // data.markdownRemark holds your post data
  const { siteMetadata } = site;
  const { frontmatter, html } = markdownRemark;

  const handleImageClick = (event) => {
    event.target.classList.toggle("full-size");
  };

  return (
    <Layout>
      <Helmet>
        <title>
          {frontmatter.title} | {siteMetadata.title}
        </title>
        <meta name="description" content={frontmatter.metaDescription} />
      </Helmet>
      <div className="blog-post-container">
        <article className="post">
          <h1 className="post-title">{frontmatter.title}</h1>
          {/* {frontmatter.thumbnail && (
            <div
              className="post-thumbnail"
              style={{ backgroundImage: `url(${frontmatter.thumbnail})` }}
            ></div>
          )} */}
          {frontmatter.thumbnail && (
            <img
              onClick={handleImageClick}
              className="post-image"
              src={frontmatter.thumbnail}
            ></img>
          )}
          <p>{frontmatter.metaDescription}</p>
        </article>
      </div>
      <Link
        to="/kontakt"
        state={{ projekt: frontmatter.title }}
        className="button -primary"
      >
        Interesse an einem ähnlichen Projekt?
      </Link>
      <br />
      <br />
      <Link to="/">Zurück</Link>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`;
